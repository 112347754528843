<template>
    <!--  -->
    <div class="expand-filter-wrapper">
        <!-- 信息中心-信息采集 -->
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <div class="filter-wrap">
                    <ExpandFilterForm :formData="formData" marginBottom="0"
                        @clickBtn="clickBtn" @changeBtnFormType="changeBtnFormType">
                        <template #lowScore="{slotData}">
                            <div class="ec-input-range">
                                <el-input
                                    v-model="slotData.value"
                                    style="width: 140px;"
                                    @input="handleTypeScore($event,'lowScore')"
                                    :placeholder="slotData.placeholder"
                                ></el-input>
                            </div>
                        </template>
                        <template #highScore="{slotData}">
                            <div class="ec-input-range">
                                <el-input
                                    v-model="slotData.value"
                                    style="width: 140px;"
                                    @input="handleTypeScore($event,'highScore')"
                                    :placeholder="slotData.placeholder"
                                ></el-input>
                            </div>
                        </template>
                    </ExpandFilterForm>
                    <div class="button-line"></div>
                    <div style="padding-bottom: 10px;">
                        <el-button type="primary"
                            @click="exportList()">导出全部数据</el-button>
                    </div>
                </div>
                <!-- 筛选 -->
                <!-- 表格 -->
                <table-data v-loading="loadingTable" :to-top="false" id="table" ref="table" :config="tableConfig"
                    :tableData="tableData">
                    <template v-slot:operation="slotData">
                        <el-button type="text"
                            @click="showDetails(slotData.data)">查看详情</el-button>
                    </template>
                </table-data>
                <!-- 分页器 -->
                <Pagination :page.sync="pageData.pageNum" :limit.sync="pageData.pageRow" :total="pageData.total" @pagination="changeTableDataGet"/>
                <!-- <dialog-wrapper class="details-dia" :dialog-obj="detailsDialog" @handleClose="handleDetailsClose">
                    <div class="import-wrap">
                        <div style="text-align: center;">
                            <phone-details :detailsObj="detailsObj" :formTitle='formTitle'></phone-details>
                        </div>
                        <div class="close-box">
                            <i class="el-icon-circle-close icon-color" @click="handleDetailsClose"></i>
                        </div>
                    </div>
                </dialog-wrapper> -->
            </div>
        </div>
    </div>
</template>

<script>
import { Loading, Error, Pagination, DialogWrapper } from "common-local"
import TableData from "@/components/scrollWrapper/Sub/TableData"
import { listToTree } from "@/libs/utils";
import ExpandFilterForm from "../Sub/ExpandFilter"
import onResize from "@/mixins/onResize"
import RecordsList from '@/libs/pageListOptimal.js';
import { downloadFile } from "@/libs/utils";
import { InterconnectionInfoCollectionStatostics } from "@/models/InterconnectionInfoCollectionStatostics.js";
export default {
    name: 'ExpandFilter',
    mixins: [onResize],
    props:['id',"formType"],
    components: { Loading, Error, TableData, ExpandFilterForm, DialogWrapper, Pagination },
    data() {
        return {
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            detailsObj: [],
            detailsDialog: {
                title: "",
                dialogVisible: false,
                width: "auto",
            },
            formData: {
                data: [

                    {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "请输入教师名称",
                    key: "teacherName"
                }, {
                    type: "cascader",
                    label: "",
                    value: "",
                    placeholder: "请选择任教班级",
                    key: "classId",
                    cascaderProps: {
                        label: "name",
                        multiple: false,
                        value: "id",
                        checkStrictly: true,
                        emitPath: false,
                    },
                    list: []
                },{
                    type: "select",
                    label: "",
                    value: "",
                    placeholder: "请选择任教科目",
                    key: "subjectId",
                    list: []
                },
                {
                    type: "slot",
                    slotName: "lowScore",
                    fixed: false,
                    label: "",
                    value: "",
                    placeholder: "请输入最低得分",
                    key: "lowScore",
                },
                {
                    type: "slot",
                    slotName: "highScore",
                    fixed: false,
                    label: "",
                    value: "",
                    placeholder: "请输入最高得分",
                    key: "highScore",
                },
                // {
                //     type: "input",
                //     label: "",
                //     value: "",
                //     placeholder: "请输入最低得分",
                //     key: "lowScore"
                // }, {
                //     type: "input",
                //     label: "",
                //     value: "",
                //     placeholder: "请输入最高得分",
                //     key: "highScore"
                // }
                ],
                btnList: [
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                    },
                ],
                btnFormType: false
            },
            tableHeight: '',
            tableConfig: {
                thead: [
                    {
                        label: "教师名称",
                        prop: "teacherName",
                        align: "center"
                    },
                    {
                        label: "任教班级",
                        minWidth: '250px',
                        prop: "className",
                        align: "center"
                    },
                    {
                        label: "任教科目",
                        prop: "subjectName",
                        align: "center"
                    },
                    {
                        label: "得分(分)",
                        labelDescription: "根据选项最高分转换为百分制后的多维度平均分",
                        prop: "score",
                        align: "center",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: '180px',
                        slotName: "operation",
                        className: 'text-spacing-reduction',
                        renderHeader: null,
                    }
                ],
                height: "",
                check: false,
                showIndex:true,
                showIndexWidth: "150px"
            },
            tableData: [],
            formTitle:'',
            pageData: {
                pageNum: 1,
                pageRow: 20,
                total: 0
            },
        }
    },
    created() {
        this.getLabel()
        this.getSubjectList()
        this.getClassList()
        this.tableDataGet()
    },
    methods: {
        /**
         * @Description:获取表格数据
         * @Author: 王梦瑶
         * @Date: 2024-08-16 19:15:21
         */
        getLabel(){
            if( this.formType == '2'){
                this.formData.data.forEach((item) =>{
                    if(item.key == 'classId'){
                        item.placeholder = '请选择负责班级'
                    }
                })
                this.formData.data = this.formData.data.filter((item) =>{
                    return item.key != 'subjectId'
                })
                this.tableConfig.thead.forEach((item) =>{
                    if(item.prop == 'className'){
                        item.label = '负责班级'
                    }
                })
                this.tableConfig.thead = this.tableConfig.thead.filter((item) =>{
                    return item.prop != 'subjectName'
                })
            }
        },
        /**
         * @Description: 获取分数
         * @Author: 王梦瑶
         * @Date: 2024-08-22 14:33:38
         */
         handleTypeScore(e,label){
            let maxvalue = e.replace(/[^\d]/g, ""); // 只能输入.和-和数字
            maxvalue = maxvalue.replace(/^\./g, ""); //第一个字符不能是.
            maxvalue = maxvalue.replace(/\.{2,}/g, "."); // 不能连续输入.
            maxvalue = maxvalue.replace(/(\.\d+)\./g, "$1"); // .后面不能再输入.
            maxvalue = maxvalue.replace(/(-)\./g, "$1"); // -后面不能输入.
            maxvalue = maxvalue.replace(/\-{2,}/g, "-"); // -只能保留一个
            maxvalue = maxvalue.replace(/(\d+|\.)-/g, "$1"); // 数字和.后面不能接-,不能出现类似11-, 12.-
            maxvalue = maxvalue.replace(/-(0){2,}/g, "$1"); // 不能出现-00,-001,-0001类似
            maxvalue = maxvalue.replace(/(-)0+(\d+)/g, "$1$2"); // 不能出现-01,-02类似
            maxvalue = maxvalue.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
            maxvalue = maxvalue.replace(/(\d{10})\d*/, "$1"); // 最多保留10位整数
            maxvalue = maxvalue.replace(/(\.\d{1})\d*/, "$1"); // 最多保留1位小数
            console.log(maxvalue,'------')
            this.formData.data.forEach((i)=>{
                if(i.key == label){
                    i.value = maxvalue
                }
            })
        },
        /**
         * @Description: 筛选栏按钮操作
         * @Author: 王梦瑶
         * @Date: 2023-2-17 11:22:02
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary":
                    // 查询
                    this.tableDataGet()
                    break
                case "reset":
                    // 查询重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                    });
                    // this.formData.data = this.$options.data.call().formData.data
                    this.tableDataGet(true)
                    break
            }
        },
        /**
         * @Description: 获取任教班级
         * @Author: 王梦瑶
         * @Date: 2024-08-16 10:42:18
         */
        getClassList(){
            let obj = {
                schoolId: this.$store.state.schoolId,
            };
            this._fet(
                "/school/schoolOrgan/listByCondition",
                obj,
            ).then((res) => {
                if (res.data.code === "200") {
                    let arr = res.data.data.list.filter((item) => {
                        return item.organType != 1;})
                    const treeData = listToTree(arr, {
                        parentKey: "parentOrg",
                    });
                    console.log(treeData,'treeData')
                    let jsonList = JSON.stringify(treeData);
                    let jsonData = jsonList.replace(
                        /\"children\":\[]/g,
                        '"children":null',
                    );
                    this.formData.data.forEach((item) => {
                        if ( item.key == 'classId' ) {
                            item.list = JSON.parse(jsonData);
                        }
                    });
                    // this.screen2[1].list = JSON.parse(jsonData);
                    // console.log("this.screen2[1].list",this.screen2[1].list)
                    // this.list = this.treeData.dataList
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
        },
        /**
         * @Description: 分页事件
         * @Author: 王梦瑶
         * @Date: 2024-08-16 11:18:30
         */
        changeTableDataGet() {
            this.$nextTick(() => {
                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0
            });
            this.tableDataGet(true)
        },
        /**
         * @Description: 获取任教科目
         * @Author: 王梦瑶
         * @Date: 2024-08-22 08:55:43
         */
        getSubjectList(){
            this._fet("/school/schoolDictValue/listByCondition", {
                schoolId: this.$store.state.schoolId,
                dictKey: "teachingSubjects",
            }).then((res) => {
                console.log(res,'res.data.data')
                this.formData.data.forEach((i)=>{
                    if(i.key == 'subjectId'){
                        i.list = res.data.data.map((item) => ({
                            label: item.name,
                            value: item.id,
                        }));
                    }
                })
            });
        },
        //获取列表数据
        tableDataGet(initPage) {
            this.loadingTable = true
            let data = {}
            if (!initPage) {
                this.pageData = this.$options.data.call().pageData
            }
            //赋值--存储筛选项及滚动条高度
            let recordsList = new RecordsList()
            if (recordsList.get(this.$route.name)) {
                this.formData.data.forEach(item => {
                    data[item.key] = recordsList.get(this.$route.name).name;
                })
            }
            this.formData.data.forEach(item => {
                data[item.key] = item.value
            })
            const InterconnectionInfoCollectionStatosticsModel = new InterconnectionInfoCollectionStatostics()
            InterconnectionInfoCollectionStatosticsModel.getSchoolCollectTeacherStatisticsList({
                ...data,
                schoolId: this.$store.state.schoolId,
                formId:this.id,
                pageNum: this.pageData.pageNum,
                pageRow: this.pageData.pageRow
            }).then(res => {
                if (res.data.code === "200") {
                    this.tableData = res.data.data.list
                    this.pageData.total = res.data.data.total
                    //赋值--存储筛选项及滚动条高度
                    this.$nextTick(() => {
                        let recordsList = new RecordsList()
                        if (recordsList.get(this.$route.name)) {
                            this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                        }
                        //移除--存储筛选项及滚动条高度
                        recordsList.remove(this.$route.name)
                        this.loadingTable = false;
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            }).finally(() => {
                this.loadingTable = false
            })
        },
        /**
         * @Description: 查看详情
         * @Author: 王梦瑶
         * @Date: 2024-08-09 15:53:54
         */
         showDetails(row) {
            this.$emit('changeComp',{
                comp:'EvaluateTeacherDetails',
                teacherId: row.teacherId,
                teacherName:row.teacherName
            })
         },
        /**
         * @Description: 导出全部
         * @Author: 王梦瑶
         * @Date: 2024-08-22 11:43:59
         */
        exportList(){
            downloadFile(
                {
                    url: "/school/schoolCollectTeacherStatistics/export",
                    method: "post",
                    form: {
                        schoolId: this.$store.state.schoolId,
                        formId: this.id
                    },
                },
                () => {
                    this.exportLoading = false;
                },
                () => {
                    this.exportLoading = false;
                },
            );
            // const InterconnectionInfoCollectionStatosticsModel = new InterconnectionInfoCollectionStatostics()
            // InterconnectionInfoCollectionStatosticsModel.exportSchoolCollectTeacherStatistics({}).then((res)=>{

            // })
        },

    }
}
</script>

<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    // justify-content: space-between;
    text-align: center;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}

.expand-filter-wrapper {
    ::v-deep .expand-filter {

        .el-form--inline .el-form-item__label,
        .btn-form-type {
            display: none;
        }
    }
}

.close-box {
    text-align: center;
    font-size: 40px;
}

.icon-color {
    color: #fff;
    cursor: pointer;
}
</style>
<style lang="scss">
.details-dia {
    .el-dialog {
        background: transparent;
        box-shadow: none;
    }

    .el-dialog .el-dialog__body {
        margin-top: 0px;
        padding: 0px;
    }

    .el-dialog__header {
        display: none !important;
    }
}
</style>
